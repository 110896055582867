<template>
  <div class="home">
    <!-- <navbar :mode="modoDeCores" @toggle="toggle"></navbar> -->
    <div class="container pt-4">
        <div class="row mb-4" style="background-color: #fff; border-radius: 20px; padding: 20px;">
            <div class="col-3">
                <img id="logo" src="https://www.uninove.br/logo-uninove.svg" alt="Logo Image">
            </div>
            <div class="col-7" style="border-left: solid 2px; border-right: solid 2px; border-color: #c3c4c5">
                <div class="row mt-3" style="place-content: center;">
                    <div class="col item-menu">
                        LIVROS DIGITAIS
                    </div>
                    <div class="col item-menu">
                        LIVROS FÍSICOS
                    </div>
                    <div class="col item-menu">
                        VÍDEOS
                    </div>
                    <div class="col item-menu">
                        CURSOS
                    </div>
                </div>
            </div>
            <div class="col-2" style=" align-self: center; ">
                LOG IN/SIGN UP
            </div>
            
        </div>
    </div>
    

    <!-- Cor de Fundo: #eef4fc -->
    <navBottom></navBottom>
    <div class="container">
        <heroHome></heroHome>
        <div class="row justify-content-center">
            <div class="col-8 search-bar p-3">
                <h3>O que você procura?</h3>

                <form id="simple" name="searchForm" method="get" target="_self" action="https:///uninove.primo.exlibrisgroup.com/discovery/search" enctype="application/x-www-form-urlencoded; charset=utf-8" onsubmit="searchPrimo()">
                    <div class="mb-3 input-group">
                    <!-- Fill in Highlighted Parameters -->
                    <input type="hidden" name="vid" value="55UNINOVE_INST:UNINOVE">
                    <input type="hidden" name="tab" value="Everything">
                    <input type="hidden" name="search_scope" value="MyInst_and_CI">
                    <input type="hidden" name="lang" value="pt">
                    <!-- Fixed parameters -->
                    <input type="hidden" name="query" id="primoQuery">
                    <input type="text" id="primoQueryTemp" class="form-control" value="" size="35">
                    <select class="form-select" id="inputGroupSelect01">
                        <option selected>Livro Digital</option>
                        <option value="1">Livro Físico</option>
                        <option value="2">Artigo</option>
                        <option value="3">Coleções</option>
                    </select>
                    <button  class="btn btn-outline-secondary" id="go" title="Search" v-on:click="searchPrimo" type="button" value="Search" alt="Search">Pesquisar</button>
                    </div>
                </form>
            </div>
      </div>
      <livrosDestaque></livrosDestaque>

      <!-- Logos Bases de pesquisa-->
        <basesLogos></basesLogos>
        <!-- teste youtube -->
            <h2>Vídeos UNINOVE</h2>
            <div class="container my-4">
                <Splide :options="options">
                    <Splide-slide v-for="video in videos" v-bind:key="video.id" class="livro">
                        <a :href="video.link_acesso">
                            <img :src="video.link_capa">
                        </a>
                    </Splide-slide>
                </Splide>
            </div>
            <hr>
        <!-- Serviços biblioteca -->
        <barraServicos></barraServicos>
    </div>
  
  </div>
</template>

<script>
// @ is an alias to /src
import heroHome from '@/components/home/hero1.vue'
import livrosDestaque from '@/components/livrosDestaque.vue'
import barraServicos from '@/components/BarraServicos.vue'
import basesLogos from '@/components/basesLogos.vue'

// import navbar from '@/components/navbar.vue'
// import navBottom from '@/components/navBottom.vue'

export default {
    name: 'Home',
    components: {
        heroHome,
        livrosDestaque,
        basesLogos,
        barraServicos
        // navbar,
        // navBottom
    },
    data () {
        return {
        modoDeCores: 'light',
        videos: [
                {
                    link_acesso: "https://www.youtube.com/watch?v=fWefml4Rmco&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/fWefml4Rmco/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBbGmYrfEHJApEDcr3wIpuWdJacqQ",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=1EYAK-JmQGc&t=3s&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/1EYAK-JmQGc/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB8-T05imxi1eAHBf8dFdnu2Nlo6Q",
                },
                {
                    link_acesso: "#",
                    link_capa: "https://i.ytimg.com/vi/Z06kjW8nG-0/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBCa2kk3JgtIsJKdCpv4EYHmija8g",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=fUQoVnqZHY4&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/fUQoVnqZHY4/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDd_DQ1f8dshwUIc2jFtqMo3_VOXA",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=Z6fZVn-Nbd8&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/Z6fZVn-Nbd8/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCZahLfh-eZXXmiN8RancJOjhrksw",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=ZjxaCqjGiZk&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/ZjxaCqjGiZk/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCuJpdmKNUJj6VhoRXQ6y18XvnYTw",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=8oZjNUzFMLY&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/8oZjNUzFMLY/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBktDLmjhAv8NmagjWon2On8B4BNw",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=bMqlSSvoXS0&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/bMqlSSvoXS0/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB0Q5SNV_0tmFI6-zZhwj9yJUfyDg",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=ClLIzPrmlKU&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/ClLIzPrmlKU/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCSKRc9vxIivqxSsccJUXn2gPDk8A",
                },

            ],
            options: {
				type: 'loop',
				pagination: false,
				perPage: 4,
				perMove: 1,
				gap: '1rem',
				breakpoints: {
					0: {
						perPage: 2,
					},
					350: {
						perPage: 3,
					},
					400: {
						perPage: 4,
					},
					576: {
						perPage: 4,
					},
					768: {
						perPage: 4,
					},
					992: {
						perPage: 4,
					},
					1200: {
						perPage: 4,
					}
				}
			}
        }
    },
    methods: {
        searchPrimo: function(){
        document.getElementById("primoQuery").value = "any,contains," + document.getElementById("primoQueryTemp").value.replace(/[,]/g, " ");
        document.forms["searchForm"].submit();
        },
        toggle() {
        if (this.modoDeCores === "dark") {
            this.modoDeCores = "light"
            console.log("Modo Claro")
            document.documentElement.setAttribute('data-theme', ""); // atualiza para null o atribudo data-theme que fica bem no começo do html
            localStorage.setItem('theme', ""); // Salva para uma próxima sessão
        } else {
            this.modoDeCores = "dark"
            console.log("Modo Escuro")
            document.documentElement.setAttribute('data-theme', "darkMode"); // atualiza para "darkMode" o atribudo data-theme que fica bem no começo do html
            localStorage.setItem('theme', "darkMode"); // Salva para uma próxima sessão
        }
        }
    }
}
</script>

<style scoped>
.search-bar {
  border: 1px solid 686868;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  color: #2c3e50;
  top: -50px;
  box-shadow: var(--shadow) 0px 3px 5px;
}


form select{
  width: 100px !important;
  flex: none;
}

form input{
  width: 500px !important;
  flex: none;
}

.item-menu{

}
</style>

