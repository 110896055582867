<template>
  <div class="row">
      <div class="col hero" >
      </div>
  </div>
</template>

<script>
export default {
  name: 'heroHome',
}
</script>

<style scoped>
.hero {
    /* background-image: url("https://stories.freepiklabs.com/storage/22475/studying-bro-2370.png"); */
    background-image: url("https://uninovepa.com/wp-content/uploads/2020/12/Hero_a_site_uninove-3.png");
    background-position-x: 50%;
    /* background-color: var(--hero); */
    display: flex;
    flex-direction: column;
    width: 65%;
    padding: 30px;
    align-self: stretch;
    overflow: hidden;
    position: relative;
    transition: background 0.3s;
    background-repeat: no-repeat;
    border-radius: 20px;
    height: 500px;
    background-size: cover;
}
</style>
